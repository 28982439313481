.faq-section {
	margin: auto;
	max-width: 700px;
	display: flex;
	flex-direction: column;
	align-items: center;
	h2 {
		font-size: 3em;
		margin-bottom: 50px;
		text-align: center;
	}
	.accordion{
		background-color: #00000010;
		box-shadow: none;
		width: 100%;
		.question{
			color: #3d3d3d
		}
	}

	@media (max-width: 768px) {
		h2 {
			font-size: 2.5em;
		}
	}
}
