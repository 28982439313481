.feature-section {
	margin-top: 50px;
	display: flex;
	flex-direction: column;
	align-items: center;
	gap: 20px;
	text-align: center;

	h2 {
		font-size: 3em;

		span {
			font-size: 1em;
			color: #58d39a;
		}
	}

	p {
		max-width: 500px;
	}

	.feature-item-container {
		margin-top: 30px;
		display: flex;
		flex-wrap: wrap;
		justify-content: center;
		gap: 20px;
		max-width: 1000px;
		.feature-item {
			display: flex;
			flex-direction: column;
			gap: 10px;
			max-width: 300px;
			.icon-container {
				padding: 15px;
				margin: auto;
				background-color: #fff8d3;
				border-radius: 50%;
				display: flex;
				align-items: center;
				justify-content: center;
				svg {
					width: 30px;
					height: 30px;
					stroke: #876f0f;
					stroke-width: 10;
					color: #fff;
				}

				


				h5 {
					font-size: 1.1em;
					color: rgb(58, 58, 58);
				}
				p {
					text-wrap: balance;
				}
			}

			.purple {
				background-color: #e1c4ff;
				svg {
					stroke: #8f2ff5;
					color: #fff;
				}
			}
		}
	}

	@media (max-width: 768px) {
		h2 {
			font-size: 2.5em;
		}
	}
}
